/*----------------------------------------*/
/*  06. Services
/*----------------------------------------*/
.tp-services {
    padding: 42px 35px 45px 35px;
    @media #{$xl} {
        padding: 27px 20px 30px 20px;
    }
    @media #{$md,$xs} {
        padding: 32px 25px 35px 25px;
    }
    &-icon {
        width: 75px;
        margin-right: 25px;
        float: left;
        margin-top: 12px;
        @media #{$md,$xs} {
            float: inherit;
            margin-right: 0px;
            margin-bottom: 25px;
        }
        & i {
            font-size: 48px;
            line-height: 1;
        }
    }
    &-text {
        &-link {
            display: inline-flex;
            align-items: center;
            & a {
                font-size: 16px;
                font-weight: 500;
                &:hover {
                    color: $theme-color;
                    padding-left: 5px;
                }
            }
            & i {
                font-size: 16px;
                transform: translateY(2px);
                display: inline-block;
                margin-right: 2px;
                line-height: 1;
            }
        }
    }
    &:hover {
        & .tp-services-icon {
            & i {
                animation-name: wobble-vertical;
                animation-duration: 1s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: 3;
            }
        }
    }
}


.tp-services-text-title {
    font-size: 24px;
    @media #{$xs} {
        font-size: 22px;
    }
}


// service home two start here
.tp-service-two-title {
    font-size: 24px;
    color: $heading-color-black;
    &:hover {
        color: $theme-color;
    }
}
.tp-service-two {
    &-img {
        position: relative;
        &-box {
            box-shadow: 0px 0px 24px 0px rgba(30, 33, 62, 0.19);
            display: inline-block;
            border-radius: 50%;
            height: 300px;
            width: 300px;
            border-radius: 50%;
            border: 10px solid $white;
            position: relative;
            overflow: hidden;
            @media #{$xl} {
                height: inherit;
                width: inherit;
            }
            &::before {
                position: absolute;
                content: '';
                height: 100%;
                width: 100%;
                background-color: rgba(254, 209, 12, 0.8);
                transform: scale(0);
                @include transition(.3s);
                z-index: 1;
            }
        }
        & img {
            object-fit: cover;
        }
    }
    &-content {
        & span {
            font-size: 16px;
            color: $body-text-color-grey;
            font-weight: 500;
            display: block;
        }
    }
    &:hover {
        & .tp-service-two-img-box {
            &:before {
                transform: scale(1);
            }
        }
        & img {
            transform: scale3d(1.1, 1.1, 1.1);
        }
    }
}
.tp-service-two-icon-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;
    z-index: 1;
    & ul {
        transform: scale(0);
        opacity: 0;
        visibility: hidden;
        height: 0px;
        overflow: hidden;
        @include transition(.3s);
        z-index: 3;
        & li {
            margin-bottom: 9px;
            list-style: none;
            &:last-child {
                margin-bottom: 0px;
            }
            & a {
                height: 50px;
                width: 50px;
                line-height: 50px;
                color: $white;
                background: $theme-color;
                display: inline-block;
                text-align: center;
                font-size: 18px;
                position: relative;
                z-index: 1;
                overflow: hidden;
                border-radius: 5px;
                &:before {
                    position: absolute;
                    content: "";
                    top: 0px;
                    left: 0px;
                    height: 100%;
                    width: 0%;
                    background: $theme-dark-color;
                    z-index: -1;
                    transform: rotate(90deg);
                    @include transition(.3s);
                }
                &:hover {
                    &:before {
                        width: 100%;
                    }
                    & i {
                        transform: rotate(360deg);
                    }
                }
            }
        }
    }
}
.tp-service-two-icon {
    display: inline-block;
    & .share-icon {
        height: 60px;
        width: 60px;
        line-height: 60px;
        display: inline-block;
        text-align: center;
        font-size: 18px;
        background: $theme-color;
        color: $white;
        border-radius: 50%;
        &:hover {
            color: $heading-color-black;
            background: $white;
        }
    }
    &:hover {
        & ul {
            transform: scale(1);
            opacity: 1;
            visibility: visible;
            height: inherit;
            overflow: inherit;
            margin-bottom: 13px;
            @media #{$xl} {
                margin-bottom: 5px;
            }
        }
    }
}

// Qualiy service start here
.tp-quality {
    text-align: center;
    &-img {
        border: 1px solid $theme-color;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        height: 300px;
        width: 300px;
        display: inline-block;
        @media #{$xl} {
            height: inherit;
            width: inherit;
        }
        &:before {
            position: absolute;
            content: '';
            height: 100%;
            width: 100%;
            top: 0px;
            left: 0px;
            background-color: rgba(254, 209, 12,0.651);
            z-index: 1;
            opacity: 0;
            visibility: hidden;
        }
        & img {
            height: 300px;
            width: 300px;
            border-radius: 50%;
            object-fit: cover;
            transform: translateY(-10px);
            @include transition(0.3s);
            @media #{$xl} {
                height: inherit;
                width: inherit;
            }
        }
    }
    &-text {
        &-icon {
            margin-top: -60px;
            position: relative;
            z-index: 2;
            & a {
                height: 100px;
                width: 100px;
                line-height: 100px;
                display: inline-block;
                text-align: center;
                font-size: 50px;
                color: $heading-color-black;
                background: $theme-yellow;
                border-radius: 50%;
                overflow: hidden;
                position: relative;
                & i {
                    position: absolute;
                    top: 55%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    @include transition(0.3s);
                    &.tp-quality-text-icon2 {
                        left: 120%;
                    }
                }
            }
        }
        & span {
            font-size: 16px;
            font-weight: 500;
            color: $theme-color;
            display: block;
            margin-bottom: 5px;
        }
    }
    &:hover {
        & .tp-quality-text-icon {
            & a {
                background: $theme-color;
                color: $white;
                position: relative;
                & i {
                    left: -120%;
                    &.tp-quality-text-icon2 {
                        top: 50%;
                        left: 50%;
                    }
                }
            }
        }
        & img {
            transform: translateY(-1px);
        }
        & .tp-quality-img {
            &:before {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
.tp-quality-text-title {
    font-size: 24px;
    color: $heading-color-black;
    &:hover {
        color: $theme-color;
    }
}
.boxarea{
    background-color: #e5b10a;
    border-radius: 50%;
    padding: 10px;
    @media #{$xs} {
        width: 100px;
        height: 50px;
    }
   
   
    display: flex;
    justify-content: center
    ;
    align-items: center;
    
}
.boxarea img{
    width: 50px;
}
//service home three start here
.tp-service-three {
    padding: 20px;
    box-shadow: 0px 5px 20px 0px rgba(5, 7, 80, 0.06);
    border-radius: 3px;
    background: $white;
    overflow: hidden;
    display: flex;
    align-items: center;
    @media #{$lg,$xs} {
        display: inherit;
    }
    &-img {
        width: 260px;
        min-width: 260px;
        margin-right: 40px;
        position: relative;
        overflow: hidden;
        @media #{$xl} {
            margin-right: 25px;
        }
        @media #{$lg,$xs} {
            width: 100%;
            min-width: inherit;
            margin-bottom: 30px;
        }
        & img {
            width: 100%;
            object-fit: cover;
        }
        &-icon {
            position: absolute;
            top: 0px;
            right: 0px;
            height: 80px;
            width: 80px;
            text-align: center;
            line-height: 102px;
            background: $theme-color;
            border-radius: 30px 0px 30px 50px;
            padding-left: 6px;
            & i {
                color: $white;
                font-size: 40px;
            }
        }
    }
    &-text {
        & p {
            font-size: 16px;
            color: $body-text-color-grey3;
        }
        &-btn {
            & a {
                height: 55px;
                line-height: 55px;
                color: $heading-color-black;
                &:hover {
                    color: $white;
                }
            }
        }
    }
    &:hover {
        & .tp-service-three-img-icon i {
            transform: rotateY(360deg);
        }
        & img {
            transform: scale3d(1.1, 1.1, 1.1);
        }
    }
}
.tp-service-three-title {
    font-size: 24px;
    color: $theme-blue;
    &:hover {
        color: $theme-color;
    }
}
.tp-service-three-text-btn {
    & a {
        color: $theme-blue;
        &:hover {
            color: $white;
        }
    }
}
.tp-service-active .tp-service-two-img-box {
    box-shadow: none;
}


//service page start here
.tp-service-four {
    border: 2px solid #ebecee;
    background: none;
    & .tp-service-three-img {
        position: relative;
        & .tp-service-three-img-overlay {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0px;
            left: 0px;
            background: rgba(254, 209, 12, 0.702);
            transform: scale(0);
            visibility: hidden;
            @include transition(0.3s);
            & .tp-service-three-img-overlay-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                height: 80px;
                width: 80px;
                text-align: center;
                line-height: 102px;
                background: $theme-color;
                border-radius: 50%;
                & i {
                    color: $white;
                    font-size: 40px;
                }
            }
        }
    }
    &:hover {
        & .tp-service-three-img-overlay {
            transform: scale(1);
            visibility: visible;
        }
        & .tp-service-three-img-icon {
            opacity: 0;
            visibility: hidden;
        }
        & .tp-service-three-text-btn a {
            background: $theme-color;
            color: $white;
            &:hover {
                background: $theme-yellow;
                color: $heading-color-black;
            }
        }
    }
}

// service details start here
.tp-faqs-left-sidebar.tp-services-sidebar {
    & ul {
        & li {
            @include transition(.3s);
            & a {
                display: flex;
                justify-content: space-between;
                align-items: center;
                & i {
                    font-size: 14px;
                }
            }
            &:hover {
                border-color: $theme-color;
            }
        }
    }
}
.tp-services-sidebar-btn {
    & a {
        display: flex;
        align-items: center;
        background: $theme-color;
        font-family: $nunitosans;
        font-weight: 700;
        color: $white;
        font-size: 20px;
        & i {
            height: 60px;
            line-height: 68px;
            width: 60px;
            display: inline-block;
            border-right: .5px solid $white;
            margin-right: 20px;
            text-align: center;
            font-size: 24px;
        }
    }
}
.tp-service-details-img {
    & img {
        width: 100%;
    }
    &-overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgba(7, 95, 51, 0.251);
        top: 0px;
        left: 0px;
        &-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            & a {
                color: $theme-dark-color;
                font-size: 18px;
                height: 100px;
                width: 100px;
                text-align: center;
                line-height: 100px;
                border-radius: 50%;
                background: $theme-yellow;
                display: inline-block;
                &:hover {
                    background: $theme-color;
                    color: $white;
                }
                @media #{$xs} {
                    height: 60px;
                    width: 60px;
                    line-height: 60px;
                    font-size: 16px;
                }
            }
        }
    }
}

.tp-service-details {
    & p {
        color: $body-text-color-grey;
        font-size: 16px;
        line-height: 27px;
        & i {
            color: $theme-color;
            line-height: 1;
            transform: translateY(6px);
            display: inline-block;
            margin-right: 12px;
        }
    }
}
.tp-service-details-subtitle {
    font-size: 36px;
    color: $heading-color-black;
    @media #{$xs} {
        font-size: 28px;
    }
}
.tp-service-details-feature {
    padding: 35px 10px 30px 10px;
    border: 6px solid $white;
    box-shadow: 0px 5px 19px 1px rgba(9, 41, 25, 0.06);
    & .tp-feature-three-icon {
        position: relative;
        & i {
            font-size: 35px;
            line-height: 1;
            display: inline-block;
            position: absolute;
            top: 29px;
            left: 30px;
        }
    }
    &:hover {
        & i {
            animation-name: wobble-vertical;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: 3;
        }
    }
}

.tp-service-details-feature-title {
    font-size: 24px;
    color: $body-text-color;
    @media #{$xl} {
        font-size: 22px;
    }
    &:hover {
        color: $heading-color-black;
    }
}
.tp-service-details-area {
    @media #{$xl,$lg,$md,$xs} {
        padding-bottom: 100px;
    }
}

.ser_form_bg{
    background: url(../img/testimonial/testimonial-img-4.jpg);
    background-repeat: no-repeat;
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    top: auto;
    bottom: -50px;
    left: 0 ;
    width: 100% ;
    padding: 20px;
}
.tp-service-two-icon .share_icon{
    font-size: 24px;
    font-weight: 600;
}
.tp-service-details-img-overlay-icon button {
    color: #0b702a;
    font-size: 18px;
    height: 100px;
    width: 100px;
    text-align: center;
    line-height: 100px;
    border-radius: 50%;
    background: #e5b10a;
    display: inline-block;
    border: none;
}
.tp-service-details-img-overlay-icon button:hover {
    background: #0b702a;
    color: #ffffff;
}