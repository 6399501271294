/* ----global css start----------- */
.icon {
  font-size: 18px;
}
.long_arrow {
  font-weight: 600;
  font-size: 20px;
}
/* ----global css end----------- */

/* ----global css end----------- */

.tp-services.active {
  background-color: #f7f7f7;
}

.arrow {
  margin-left: 10px;
  display: inline-block;
  transition: transform 0.3s;
}

.arrow.down {
  transform: rotate(0deg);
}

.arrow.up {
  transform: rotate(180deg);
}

.dropdown-content {
  background-color: #fff;
  padding: 20px;
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dhaka:hover path,
.mymensingh:hover path,
.sylhet:hover path,
.khulna:hover path,
.rajshahi:hover path,
.barisal:hover path,
.rangpur:hover path,
.chittagong:hover path {
  fill: #7d88a1;
}
.cls-2 {
  fill: #9d9d9d;
}
/* .hoverClass:hover {
  fill: red;
} */
